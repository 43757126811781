import axios from "axios";
import { BASE_URL } from "../../config";
import {
  FETCH_PROFILE_STATS,
  FETCH_PROFILE_STATS_SUCCESS,
  FETCH_PROFILE_STATS_FAILURE,
} from "./statTypes";

const fetchProfileStats = () => {
  return {
    type: FETCH_PROFILE_STATS,
  };
};
const fetchProfileStatsSuccess = (stats) => {
  return {
    type: FETCH_PROFILE_STATS_SUCCESS,
    payload: stats,
  };
};
const fetchProfileStatsFailure = (error) => {
  return {
    type: FETCH_PROFILE_STATS_FAILURE,
    payload: error,
  };
};

// Fetch Profile Status Stats
export const fetchCookProfileStats = () => {
  return (dispatch) => {
    dispatch(fetchProfileStats());
    axios({
      method: "GET",
      url: `/admin/stats/cook-profile-status-stats`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const stats = res.data;
        dispatch(fetchProfileStatsSuccess(stats));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchProfileStatsFailure(errorMsg));
      });
  };
};
