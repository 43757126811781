import React from "react";

// libraries
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBan } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const CustomToast = ({
  show,
  setShow,
  toastBody,
  delay = 5000,
  success = true,
}) => {
  return (
    <div aria-live="polite" aria-atomic="true">
      <Toast
        onClose={setShow}
        show={show}
        delay={delay}
        autohide
        style={{
          position: "fixed",
          top: 70,
          right: 40,
          zIndex: 1000000,
        }}
      >
        <Toast.Header>
          {success ? (
            <>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success mr-1"
              />
              <strong className="mr-4"> Success</strong>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faBan} className="text-danger mr-1" />
              <strong className="mr-4"> Failure</strong>
            </>
          )}
          <small>{moment().fromNow()}</small>
        </Toast.Header>
        <Toast.Body>{toastBody}</Toast.Body>
      </Toast>
    </div>
  );
};

export default CustomToast;
