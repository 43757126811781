import {
    FETCH_ALL_CAKES_REQUEST,
    FETCH_ALL_CAKES_SUCCESS,
    FETCH_ALL_CAKES_FAILURE,
    FETCH_ALL_DECORATIONS_REQUEST,
    FETCH_ALL_DECORATIONS_SUCCESS,
    FETCH_ALL_DECORATIONS_FAILURE,
  } from "./types";
  
  const INITIAL_STATE = {
    cakeData: { loading: false, error: "", cakes: [] },
    decorationData: { loading: false, error: "", decorations: [] },
  };
  
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      //   CAKES
      case FETCH_ALL_CAKES_REQUEST:
        return {
          ...state,
          cakeData: {
            loading: true,
            error: "",
            cakes: [],
          },
        };
      case FETCH_ALL_CAKES_SUCCESS:
        return {
          ...state,
          cakeData: {
            loading: false,
            error: "",
            cakes: action.payload,
          },
        };
      case FETCH_ALL_CAKES_FAILURE:
        return {
          ...state,
          cakeData: {
            loading: false,
            error: action.payload,
            cakes: [],
          },
        };
  
      //   DECORATIONS
      case FETCH_ALL_DECORATIONS_REQUEST:
        return {
          ...state,
          decorationData: {
            loading: true,
            error: "",
            decorations: [],
          },
        };
      case FETCH_ALL_DECORATIONS_SUCCESS:
        return {
          ...state,
          decorationData: {
            loading: false,
            error: "",
            decorations: action.payload,
          },
        };
      case FETCH_ALL_DECORATIONS_FAILURE:
        return {
          ...state,
          decorationData: {
            loading: false,
            error: action.payload,
            decorations: [],
          },
        };
  
      default:
        return state;
    }
  };
  
  export default reducer;
  