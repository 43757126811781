import {
  FETCH_ALL_INGREDIENTS_REQUEST,
  FETCH_ALL_INGREDIENTS_SUCCESS,
  FETCH_ALL_INGREDIENTS_FAILURE,
  FETCH_ALL_APPLIANCES_REQUEST,
  FETCH_ALL_APPLIANCES_SUCCESS,
  FETCH_ALL_APPLIANCES_FAILURE,
} from "./types";

const INITIAL_STATE = {
  ingredientData: { loading: false, error: "", ingredients: [] },
  applianceData: { loading: false, error: "", appliances: [] },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //   INGREDIENTS
    case FETCH_ALL_INGREDIENTS_REQUEST:
      return {
        ...state,
        ingredientData: {
          loading: true,
          error: "",
          ingredients: [],
        },
      };
    case FETCH_ALL_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredientData: {
          loading: false,
          error: "",
          ingredients: action.payload,
        },
      };
    case FETCH_ALL_INGREDIENTS_FAILURE:
      return {
        ...state,
        ingredientData: {
          loading: false,
          error: action.payload,
          ingredients: [],
        },
      };

    //   APPLIANCES
    case FETCH_ALL_APPLIANCES_REQUEST:
      return {
        ...state,
        applianceData: {
          loading: true,
          error: "",
          appliances: [],
        },
      };
    case FETCH_ALL_APPLIANCES_SUCCESS:
      return {
        ...state,
        applianceData: {
          loading: false,
          error: "",
          appliances: action.payload,
        },
      };
    case FETCH_ALL_APPLIANCES_FAILURE:
      return {
        ...state,
        applianceData: {
          loading: false,
          error: action.payload,
          appliances: [],
        },
      };

    default:
      return state;
  }
};

export default reducer;
