import React from "react";

// import loaderImg from "../../assets/loader.gif";
import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
// import styled from "styled-components";

// const LoadingContainer = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #f8f8f8ad;
//   z-index: 299999;
// `;

const FullPageLoader = (props) => {
  if (!props.loading) return null;
  return (
    <div>
      <div
        style={{
          zIndex: "999999999999999",
          position: "fixed",
          width: "100%",
        }}
      >
        <ProgressBar now={60} striped animated label={`loading...`} />
      </div>
    </div>
  );
};

const MSP = (state) => ({ loading: state.GlobalLoadingState.globalLoading });

export default connect(MSP)(FullPageLoader);
