import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";
import { BASE_URL } from "./config";

Sentry.init({
  dsn: "https://be6f03eaba09461381cdc5886f52e6fa@o4506024833253376.ingest.sentry.io/4505276593012736",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.coox\.in/],
    }),
  ],
  tracesSampleRate: 1.0,
});

axios.defaults.baseURL = BASE_URL;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

document.body.addEventListener("click", function (event) {
  if (event.target.closest(".side-nav-item"))
    document.body.classList.remove("sidebar-enable");
  event.target.closest(".left-side-menu, .side-nav") ||
    document.body.classList.remove("sidebar-enable");
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
