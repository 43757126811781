import {
  FETCH_ALL_CUISINES_REQUEST,
  FETCH_ALL_CUISINES_SUCCESS,
  FETCH_ALL_CUISINES_FAILURE,
} from "./types";

import axios from "axios";

const fetchAllCuisinesRequest = () => {
  return {
    type: FETCH_ALL_CUISINES_REQUEST,
  };
};
const fetchAllCuisinesSuccess = (data) => {
  return {
    type: FETCH_ALL_CUISINES_SUCCESS,
    payload: data,
  };
};
const fetchAllCuisinesError = (err) => {
  return {
    type: FETCH_ALL_CUISINES_FAILURE,
    payload: err,
  };
};

export const fetchAllCuisines = (haveIng) => {
  if (haveIng) {
    return (dispatch) => {
      dispatch(fetchAllCuisinesRequest());
      axios({
        method: "GET",
        url: `/cuisines/list?have_dishes=${true}?ordered_separately=${true}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.accessToken,
        },
        params: {
          have_dishes: true,
          ordered_separately: true,
        },
      })
        .then((res) => {
          const data = res.data.cuisines;
          dispatch(fetchAllCuisinesSuccess(data));
        })
        .catch((err) => {
          const errorMsg = err.response?.data.message;
          dispatch(fetchAllCuisinesError(errorMsg));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(fetchAllCuisinesRequest());
      axios({
        method: "GET",
        url: "/cuisines/list",
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.accessToken,
        },
      })
        .then((res) => {
          const data = res.data.cuisines;
          dispatch(fetchAllCuisinesSuccess(data));
        })
        .catch((err) => {
          const errorMsg = err.response?.data.message;
          dispatch(fetchAllCuisinesError(errorMsg));
        });
    };
  }
};
