import {
  FETCH_DISH_TYPES_REQUEST,
  FETCH_DISH_TYPES_SUCCESS,
  FETCH_DISH_TYPES_FAILURE,
} from "./types";

import axios from "axios";

const fetchDishTypesRequest = () => {
  return {
    type: FETCH_DISH_TYPES_REQUEST,
  };
};
const fetchDishTypesSuccess = (data) => {
  return {
    type: FETCH_DISH_TYPES_SUCCESS,
    payload: data,
  };
};
const fetchDishTypesError = (err) => {
  return {
    type: FETCH_DISH_TYPES_FAILURE,
    payload: err,
  };
};

export const fetchDishTypes = () => {
  return (dispatch) => {
    dispatch(fetchDishTypesRequest());
    axios({
      method: "GET",
      url: `/dishes/types`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data;
        dispatch(fetchDishTypesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchDishTypesError(errorMsg));
      });
  };
};
