import axios from 'axios';
import { BASE_URL } from '../../config';
import {
	FETCH_OPS_STATS,
	FETCH_OPS_STATS_SUCCESS,
	FETCH_OPS_STATS_FAILURE,
} from './statTypes';

const fetchOpsStats = () => {
	return {
		type: FETCH_OPS_STATS,
	};
};
const fetchOpsStatsSuccess = (stats) => {
	return {
		type: FETCH_OPS_STATS_SUCCESS,
		payload: stats,
	};
};
const fetchOpsStatsFailure = (error) => {
	return {
		type: FETCH_OPS_STATS_FAILURE,
		payload: error,
	};
};

// Fetch Ops Status Stats
export const fetchCookOpsStats = () => {
	return (dispatch) => {
		dispatch(fetchOpsStats());
		axios({
			method: 'GET',
			url: `${BASE_URL}/admin/cook/ops-stats`,
			headers: {
				Authorization:
					'Bearer ' +
					JSON.parse(localStorage.getItem('authToken'))?.accessToken,
			},
		})
			.then((res) => {
				const stats = res.data;
				dispatch(fetchOpsStatsSuccess(stats));
			})
			.catch((err) => {
				const errorMsg = err.response?.data.message;
				dispatch(fetchOpsStatsFailure(errorMsg));
			});
	};
};
