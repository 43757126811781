import axios from "axios";
import { BASE_URL } from "../../config";
import {
  FETCH_LEVEL_STATS,
  FETCH_LEVEL_STATS_SUCCESS,
  FETCH_LEVEL_STATS_FAILURE,
} from "./statTypes";

const fetchLevelStats = () => {
  return {
    type: FETCH_LEVEL_STATS,
  };
};
const fetchLevelStatsSuccess = (stats) => {
  return {
    type: FETCH_LEVEL_STATS_SUCCESS,
    payload: stats,
  };
};
const fetchLevelStatsFailure = (error) => {
  return {
    type: FETCH_LEVEL_STATS_FAILURE,
    payload: error,
  };
};

// Fetch Ops Status Stats
export const fetchCookLevelStats = () => {
  return (dispatch) => {
    dispatch(fetchLevelStats());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/cook/level-stats`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const stats = res;
        dispatch(fetchLevelStatsSuccess(stats));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchLevelStatsFailure(errorMsg));
      });
  };
};
