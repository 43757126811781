import { SET_CROPPED_IMAGE_DATA, CLEAR_CROPPED_IMAGE_DATA } from "./types";

const INITIAL_STATE = {
  imageToShow: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CROPPED_IMAGE_DATA:
      return action.payload;

    case CLEAR_CROPPED_IMAGE_DATA:
      return {
        imageToShow: "",
      };

    default:
      return state;
  }
};

export default reducer;
