import {
	FETCH_ALL_USERS_REQUEST,
	FETCH_ALL_USERS_SUCCESS,
	FETCH_ALL_USERS_FAILURE,
} from './getuserTypes';

const initialState = {
	loading: false,
	users: [],
	error: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_USERS_REQUEST:
			return { ...state, loading: true };
		case FETCH_ALL_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				users: action.payload,
				error: '',
			};
		case FETCH_ALL_USERS_FAILURE:
			return {
				...state,
				loading: false,
				users: [],
				error: action.payload,
			};

		default:
			return state;
	}
};

export default reducer;
