import {
  FETCH_CITIES_AND_AREAS_REQUEST,
  FETCH_CITIES_AND_AREAS_SUCCESS,
  FETCH_CITIES_AND_AREAS_FAILURE,
} from "./types";

import axios from "axios";

const fetchCitiesAndAreasRequest = () => {
  return { type: FETCH_CITIES_AND_AREAS_REQUEST };
};
const fetchCitiesAndAreasSuccess = (data) => {
  return { type: FETCH_CITIES_AND_AREAS_SUCCESS, payload: data };
};
const fetchCitiesAndAreasFailure = (err) => {
  return { type: FETCH_CITIES_AND_AREAS_FAILURE, payload: err };
};

// Fetch All Cities And Areas
export const fetchAllCitiesAndAreas = () => {
  return (dispatch) => {
    dispatch(fetchCitiesAndAreasRequest());
    axios({
      method: "GET",
      url: `/admin/cook/city-areas?type=current_city`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data.data.cities;
        dispatch(fetchCitiesAndAreasSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchCitiesAndAreasFailure(errorMsg));
      });
  };
};
