import React, { useEffect } from "react";
import "./signIn.css";
// Redux
import { connect } from "react-redux";
import { authUserSet, authTokenSet, authEmployeeSet } from "../../redux";

// 3rd party libraries
import axios from "axios";
import * as Yup from "yup";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import { withFormik, Form, Field } from "formik";
import { Link } from "react-router-dom";

// components
import RandomQuotesByRajat from "./GenerateRandomQuotes";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";

const Index = React.memo(({ errors, touched, isSubmitting }) => {
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  useEffect(() => {
    localStorage.clear();
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "scroll");
  }, []);

  const [count, setCount] = useState(0);

  // const imageUrls = [
  //   "/assets/images/bg-auth1.jpg",
  //   "/assets/images/bg-auth2.jpeg",
  //   "/assets/images/bg-auth3.jpeg",
  //   "/assets/images/bg-auth4.jpeg",
  //   "/assets/images/bg-auth5.jpeg",
  //   "/assets/images/bg-auth6.jpeg",
  //   "/assets/images/bg-auth7.jpeg",
  //   "/assets/images/bg-auth8.jpeg",
  // ];
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/website/image?type=admin_login");
        setImageUrls(response.data.images);
      } catch (error) {
        alert(error);
        console.error("Error fetching images:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const id = setInterval(
      () =>
        setCount((oldCount) =>
          oldCount < (imageUrls?.length - 1) ? oldCount + 1 : 0
        ),
      5000
    );
    return () => {
      clearInterval(id);
    };
  }, [imageUrls, count]);
  const divStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${imageUrls[count]?.url})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const validateNumber = (event) => {
    if (event.target.value.length > 10) {
      event.target.value = event.target.value.slice(0, 10);
    }
  };

  return (
    <div className="auth-fluid">
      {/* <!--Auth fluid left content --> */}
      <Col md={4} xs={12} className="p-0">
        <div className="auth-fluid-form-box">
          <div className="align-items-center d-flex h-100">
            <div className="card-body ">
              {/* <!-- Logo --> */}
              <div className="auth-brand text-center text-lg-left">
                <Link to="/" className="logo-dark">
                  <span>
                    <img
                      src="/assets/images/coox_admin_updated_logo.png"
                      alt="logo"
                      height="50"
                    />
                  </span>
                </Link>
                <Link to="/" className="logo-light">
                  <span>
                    <img src="/assets/images/coox_admin_updated_logo.png" />
                  </span>
                </Link>
                {/* <h6>COOX Admin Panel</h6> */}
              </div>
              {/* <hr /> */}
              {/* <!-- title--> */}
              <br />
              <br />
              <br />
              <h4 className="mt-0">Sign In</h4>
              <p className="text-muted mb-4">
                Enter your phone number and password to access the admin panel.
              </p>

              {/* <!-- form --> */}
              <Form action="#">
                <div className="form-group">
                  <label htmlFor="emailaddress">Phone Number</label>
                  <Field
                    type="number"
                    name="mobile"
                    placeholder="☎ Phone Number"
                    className="form-control"
                    onInput={validateNumber}
                    autoFocus
                  />
                  <div className="text-danger">
                    {touched.mobile && errors.mobile && errors.mobile}
                  </div>
                </div>
                <div className="form-group ">
                  {/* <a href='pages-recoverpw-2.html' className='text-muted float-right'>
									<small>Forgot your password?</small>
								</a> */}
                  <label htmlFor="password">Password</label>
                  <div className="d-flex input-group border border-2 rounded rounded-2">
                    <Field
                      type={passwordType}
                      name="password"
                      placeholder="🔒 Enter your password"
                      className="form-control border border-0 w-60"
                    />
                    <span>
                      <div
                        className="passwordHide h-100 text-center d-flex align-items-center pr-1  "
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fa-solid fa-eye-slash"></i>
                        )}
                      </div>
                    </span>
                  </div>

                  <div className="text-danger">
                    {touched.password && errors.password && errors.password}
                  </div>
                </div>
                {/* <div className='form-group mb-3'>
								<div className='custom-control custom-checkbox'>
									<input
										type='checkbox'
										className='custom-control-input'
										id='checkbox-signin'
									/>
									<label className='custom-control-label' htmlFor='checkbox-signin'>
										Remember me
									</label>
								</div>
							</div> */}
                <div className="form-group mb-0 text-center">
                  <button
                    className="btn btn-block lognInBtn"
                    disabled={isSubmitting}
                  >
                    <i className="mdi mdi-login"></i> Log In{" "}
                    {isSubmitting ? "..." : ""}
                  </button>
                </div>
              </Form>
              {/* <!-- end form--> */}
            </div>
            {/* <!-- end .card-body --> */}
          </div>
          {/* <!-- end .align-items-center.d-flex.h-100--> */}
        </div>
        <Row>
          <div className="auth-user-testimonial">
            {/* <h2 className='mb-3'>I love the color!</h2> */}
            <p className="lead font-weight-bolder font-italic text-left m-0 ">
              <i className="mdi mdi-format-quote-open mdi-36px"></i>
              <RandomQuotesByRajat />
              <i className="mdi mdi-format-quote-close mdi-36px"></i>
            </p>
            <br />
            <p className="font-weight-bolder font-italic mt-2">
              - Rajat Johri, CTO COOX
            </p>
          </div>
        </Row>
      </Col>
      {/* <!-- end auth-fluid-form-box--> */}

      {/* <!-- Auth fluid right content --> */}
      <Col className="p-0">
        <div style={{ width: "100%", height: "100%" }}>
          <img
            src={imageUrls[count]?.url}
            alt="background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain", // Fits the image inside the container while preserving aspect ratio
              display: "block", // Prevents extra space below the image
            }}
          />
        </div>
      </Col>
      {/* <!-- end Auth fluid right content --> */}
    </div>
  );
});

const mapDispatchToProps = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    authEmployeeSet: (authEmployeeData) =>
      dispatch(authEmployeeSet(authEmployeeData)),
  };
};

const FormikLogin = withFormik({
  mapPropsToValues() {
    return { mobile: "", password: "" };
  },
  validationSchema: Yup.object().shape({
    mobile: Yup.string().required(),
    password: Yup.string()
      .min(13)
      .matches(
        /^(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/^(?=.*[0-9])/, "Password must contain at least one digit")
      .matches(
        /^(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character"
      )
      .matches(/^\S*$/, "No spaces are allowed in the password")
      .required("Password is required"),
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    axios({
      method: "post",
      url: "/auth/login-and-signup",
      data: {
        mobile: String(values.mobile),
        password: values.password,
        source: "admin_panel",
      },
    })
      .then((res) => {
        localStorage.setItem("authUser", JSON.stringify(res.data.user));
        localStorage.setItem("authToken", JSON.stringify(res.data.token));
        localStorage.setItem("authEmployee", JSON.stringify(res.data.employee));

        props.authUserSet(res.data.user);
        props.authTokenSet(res.data.token);
        props.authEmployeeSet(res.data.employee);
        resetForm();
        if (
          res.data.user.roles.includes(ROLES.MARKETER) ||
          res.data.user.roles.includes(ROLES.SENIORMARKETER)
        ) {
          props.history.push(ROUTES.SALESDASHBOARD);
        } else if (res.data.user.roles.includes(ROLES.DIGITALMARKETER)) {
          props.history.push(ROUTES.CUISINESANDCOMBOSDASHBOARD);
        } else {
          props.history.push(ROUTES.HOME);
        }
      })
      .catch((err) => {
        const errorMsg = err.response && err.response.data.message;
        setErrors({
          password: errorMsg,
        });
        alert(err.response && err.response.data.message);
      })
      .finally(() => setSubmitting(false));
  },
})(Index);

export default connect(null, mapDispatchToProps)(FormikLogin);
