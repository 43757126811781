import { authEmployeeSet } from "./loginActions";
import { AUTH_USER_SET, AUTH_TOKEN_SET, AUTH_EMPLOYEE_SET } from "./loginTypes";

const initialState = {
  authUser: {},
  authToken: {},
  authEmployee:{},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_SET:
      return {
        ...state,
        authUser: action.payload,
      };
    case AUTH_TOKEN_SET:
      return { ...state, authToken: action.payload };
    case AUTH_EMPLOYEE_SET:
        return { ...state, authEmployee: action.payload };
    default:
      return state;
  }
};

export default reducer;
