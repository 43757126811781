import {
  FETCH_BOOKING_DATA_REQUEST,
  FETCH_BOOKING_DATA_SUCCESS,
  FETCH_BOOKING_DATA_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";
import { fetchDescribeYourOrder } from "../FetchBookingTabsData/actions";

const fetchBookingDataRequest = () => {
  return {
    type: FETCH_BOOKING_DATA_REQUEST,
  };
};
const fetchBookingDataSuccess = (data) => {
  return {
    type: FETCH_BOOKING_DATA_SUCCESS,
    payload: data,
  };
};
const fetchBookingDataFailure = (error) => {
  return {
    type: FETCH_BOOKING_DATA_FAILURE,
    payload: error,
  };
};

// Fetch booking data
export const fetchBookingData = (bookingId) => {
  return (dispatch) => {
    dispatch(fetchBookingDataRequest());
    dispatch(fetchDescribeYourOrder(bookingId))
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/booking/${bookingId}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data.data.booking;
        dispatch(fetchBookingDataSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchBookingDataFailure(errorMsg));
      });
  };
};
