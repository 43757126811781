import {
  FETCH_ALL_DISHES_REQUEST,
  FETCH_ALL_DISHES_SUCCESS,
  FETCH_ALL_DISHES_FAILURE,
} from "./types";

import axios from "axios";

const fetchAllDishesRequest = () => {
  return {
    type: FETCH_ALL_DISHES_REQUEST,
  };
};
const fetchAllDishesSuccess = (data) => {
  return {
    type: FETCH_ALL_DISHES_SUCCESS,
    payload: data,
  };
};
const fetchAllDishesError = (err) => {
  return {
    type: FETCH_ALL_DISHES_FAILURE,
    payload: err,
  };
};

// Fetch all dishes
export const fetchAllDishes = (is_exotic) => {
  return (dispatch) => {
    let url;
    if (is_exotic ? (url = `/dishes?is_exotic=${true}`) : (url = "/dishes"))
      dispatch(fetchAllDishesRequest());
    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        let allDishes = res.data;
        dispatch(fetchAllDishesSuccess(allDishes));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchAllDishesError(errorMsg));
      });
  };
};
