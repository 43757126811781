import {
	SET_ACTIVE_PAGE_ASSIGNED_COOKS,
	SET_ACTIVE_PAGE_HOMEPAGE,
} from './activePageTypes';

export const setActivePageAssignedCooks = (activePage) => {
	return {
		type: SET_ACTIVE_PAGE_ASSIGNED_COOKS,
		payload: activePage,
	};
};
export const setActivePageHomePage = (activePage) => {
	return {
		type: SET_ACTIVE_PAGE_HOMEPAGE,
		payload: activePage,
	};
};
