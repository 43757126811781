import {
  SET_ACTIVE_PAGE_ASSIGNED_COOKS,
  SET_ACTIVE_PAGE_HOMEPAGE,
} from "./activePageTypes";

const initialState = {
  activePageAssignedCooks: 1,
  activePageHomePage: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE_ASSIGNED_COOKS:
      return {
        activePageAssignedCooks: action.payload,
      };
    case SET_ACTIVE_PAGE_HOMEPAGE:
      return {
        activePageHomePage: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
