import {
  FETCH_ALL_COOKS_REQUEST,
  FETCH_ALL_COOKS_SUCCESS,
  FETCH_ALL_COOKS_FAILURE,
  SET_ALL_COOKS,
  FLAG_COOK_SUCCESS,
  FLAG_COOK_REQUEST,
  FLAG_COOK_FAILURE,
  SAVE_HOME_PAGE_CURRENT_FILTERS,
  SAVE_ASSIGNED_COOKS_CURRENT_FILTERS,
} from "./fetchAllCooksTypes";

const initialState = {
  loading: false,
  cooks: [],
  error: "",
  setFlagLoading: false,

  // Home page filters state
  homePageCurrentFilters: {
    profileStatusFilter: null,
    opsStatsFilter: null,
    searchTerm: null,
    currentCityFilter: null,
    departmentFilter: null,
    postFilter: null,
    levelFilter: null,
    typeFilter: null,
    flagged: null,
    profile_percentage: "",
    data_source: "",
    logged_inFilter: null,
    serviceTypeFilter: "",
    area: null,
    bookings_completed: null,
  },

  // Assigned cooks filters state
  assignedCooksCurrentFilters: {
    profileStatusFilter: null,
    opsStatsFilter: null,
    searchTerm: null,
    currentCityFilter: null,
    departmentFilter: null,
    postFilter: null,
    levelFilter: null,
    typeFilter: null,
    flagged: null,
    profile_percentage: "",
    data_source: "",
    logged_inFilter: null,
    area: null,
    bookings_completed: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COOKS_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_COOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        cooks: action.payload,
        error: "",
      };
    case FETCH_ALL_COOKS_FAILURE:
      return {
        ...state,
        loading: false,
        cooks: [],
        error: action.payload,
      };
    case SET_ALL_COOKS:
      return {
        ...state,
        cooks: action.payload,
      };
    case FLAG_COOK_REQUEST:
      return {
        ...state,
        setFlagLoading: true,
      };
    case FLAG_COOK_SUCCESS:
      return {
        ...state,
        setFlagLoading: false,
      };
    case FLAG_COOK_FAILURE:
      return {
        ...state,
        setFlagLoading: false,
      };
    case SAVE_HOME_PAGE_CURRENT_FILTERS:
      return {
        ...state,
        homePageCurrentFilters: {
          ...state.homePageCurrentFilters,
          ...action.payload,
        },
      };
    case SAVE_ASSIGNED_COOKS_CURRENT_FILTERS:
      return {
        ...state,
        assignedCooksCurrentFilters: {
          ...state.assignedCooksCurrentFilters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
