import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// Constants
import * as ROUTES from "../../constants/routes";
const DekodeWidget = ({
  userId,
  dekodeWidgetKey = "23f868ce2761417cb3269a3b34e134f8",
}) => {
  const instanceRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src =
        "https://dekodestorage.blob.core.windows.net/public/js/main.js";
      script.async = true;
      script.onload = () => {
        createDekodeVideo();
      };
      // Ensure instanceRef.current is not null before appending
      if (instanceRef.current) {
        instanceRef.current.appendChild(script);
      }
    };
    const createDekodeVideo = () => {
      instanceRef.current.innerHTML = `<dekode-video context='${userId}'
key='${dekodeWidgetKey}'></dekode-video>`;
    };
    if (location.pathname === ROUTES.HOME) {  //the widget will reload 
      loadScript();
    }
    return () => {
      const script = document.querySelector(
        `script[src="https://dekodestorage.blob.core.windows.net/public/js/main.js"]`
      );
      if (script) {
        script.onload = null;
        script.remove();
      }
    };
  }, [location.pathname,userId, dekodeWidgetKey]);
  return location?.pathname === ROUTES.HOME && <div ref={instanceRef} />;
};
export default DekodeWidget;
