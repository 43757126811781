import React from "react";
// constants
import { quotesByRajat } from "../../constants/quotes";

const GenerateRandomQuotes = () => {
  return (
    <span className="text-black text-left">
      {
        quotesByRajat[
          Math.floor(Math.random() * (quotesByRajat.length - 0) + 0)
        ]
      }
    </span>
  );
};

export default GenerateRandomQuotes;
