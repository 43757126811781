import { combineReducers } from "redux";

import loginReducer from "./Login/loginReducer";
import profileStatReducer from "./CookProfileStats/statReducer";
import opsStatReducer from "./CookOpsStats/statReducer";
import levelStatReducer from "./CookLevelStats/statReducer";
import addAdminReducer from "./AddAdmin/addAdminReducer";
import getUserReducer from "./GetUsers/getUserReducer";
import fetchAllCooksReducer from "./FetchAllCooksWithFilters/fetchAllCooksReducer";
import activePageReducer from "./ActivePage/activePageReducer";
import fetchBookingTabsDataReducer from "./FetchBookingTabsData/reducer";
import fetchBookingDetailsReducer from "./FetchBookingDetails/reducer";
import fetchIngredientsAndAppliancesReducer from "./FetchIngredientsAndAppliances/reducer";
import fetchCakesAndDecorationsReducer from "./FetchCakesAndDecorations/reducer"
import FetchCitiesAndAreasReducer from "./FetchCitiesAndAreas/reducer";
import GlobalLoadingReducer from "./GlobalLoadingIndicator/reducer";
import CroppedImageReducer from "./CroppedImageData/reducer";
import CookEditHistoryReducer from "./CookEditHistory/reducer";
import MappingsReducer from "./Mappings/reducer";
import FetchAllCuisinesReducer from "./FetchAllCuisines/reducer";
import FetchAllDishesReducer from "./FetchAllDishes/reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  cookProfileStats: profileStatReducer,
  cookOpsStats: opsStatReducer,
  addAdminState: addAdminReducer,
  cookLevelStats: levelStatReducer,
  usersState: getUserReducer,
  fetchAllCooksWithFiltersState: fetchAllCooksReducer,
  activePageState: activePageReducer,
  BookingTabsState: fetchBookingTabsDataReducer,
  BookingDetails: fetchBookingDetailsReducer,
  AllIngredientsAndAppliances: fetchIngredientsAndAppliancesReducer,
  AllCakesAndDecorations:fetchCakesAndDecorationsReducer,
  CitiesAndAreasState: FetchCitiesAndAreasReducer,
  GlobalLoadingState: GlobalLoadingReducer,
  CroppedImageState: CroppedImageReducer,
  CookEditHistoryState: CookEditHistoryReducer,
  MappingsState: MappingsReducer,
  AllCuisines: FetchAllCuisinesReducer,
  AllDishes: FetchAllDishesReducer,
});

export default rootReducer;
