import {
  FETCH_DESCRIBE_YOUR_ORDER_REQUEST,
  FETCH_DESCRIBE_YOUR_ORDER_SUCCESS,
  FETCH_DESCRIBE_YOUR_ORDER_FAILURE,
  SET_DESCRIBE_YOUR_ORDER,
  UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES,
  FETCH_INGREDIENTS_REQUEST,
  FETCH_INGREDIENTS_SUCCESS,
  FETCH_INGREDIENTS_FAILURE,
  FETCH_APPLIANCES_REQUEST,
  FETCH_APPLIANCES_SUCCESS,
  FETCH_APPLIANCES_FAILURE,
  SET_APPLIANCES,
  SET_INGREDIENTS,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

// -------------------------DESCRIBE ORDER-----------------

const fetchDescribeYourOrderRequest = () => {
  return {
    type: FETCH_DESCRIBE_YOUR_ORDER_REQUEST,
  };
};
const fetchDescribeYourOrderSuccess = (data) => {
  return {
    type: FETCH_DESCRIBE_YOUR_ORDER_SUCCESS,
    payload: data,
  };
};
const fetchDescribeYourOrderFailure = (error) => {
  return {
    type: FETCH_DESCRIBE_YOUR_ORDER_FAILURE,
    payload: error,
  };
};
export const setDescribeYourOrder = (data) => {
  return {
    type: SET_DESCRIBE_YOUR_ORDER,
    payload: data,
  };
};
export const updateDescribeYourOrderBookingDishes = (data) => {
  return {
    type: UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES,
    payload: data,
  };
};

// Fetch describe your order data
export const fetchDescribeYourOrder = (bookingId) => {
  return (dispatch) => {
    dispatch(fetchDescribeYourOrderRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/booking/describe_order/${bookingId}?with_ingredient_counts=${true}`,
    })
      .then((res) => {
        let data = res.data;
        data.booking_dishes.forEach((dish) => {
          dish._destroy = false;
          dish._new = false;
        });
        dispatch(fetchDescribeYourOrderSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchDescribeYourOrderFailure(errorMsg));
      });
  };
};

// -------------------------INGREDIENTS-----------------
const fetchIngredientsRequest = () => {
  return {
    type: FETCH_INGREDIENTS_REQUEST,
  };
};
const fetchIngredientsSuccess = (data) => {
  return {
    type: FETCH_INGREDIENTS_SUCCESS,
    payload: data,
  };
};
const fetchIngredientsFailure = (error) => {
  return {
    type: FETCH_INGREDIENTS_FAILURE,
    payload: error,
  };
};

export const setIngredients = (data) => {
  return {
    type: SET_INGREDIENTS,
    payload: data,
  };
};

// Fetch Ingredients data
export const fetchIngredients = (bookingId, type) => {
  return (dispatch) => {
    dispatch(fetchIngredientsRequest());
    axios({
      method: "GET",
      url: `admin/booking/${bookingId}/ingredients`,
    })
      .then((res) => {
        let buttonType = type || null;
        let data = res.data;

        switch (buttonType) {
          case "GetRoundoffQty":
            data.ingredient_details.forEach((i) =>
              i.ingredients.forEach((j) => {
                j.admin_suggested = {
                  _destroy: false,
                  _new: false,
                  is_required: true,
                  total_quantity: j.suggested_quantities
                    .split(",")
                    .sort((a, b) => {
                      return (
                        Math.abs(j.system_suggested?.total_quantity - a) -
                        Math.abs(j.system_suggested?.total_quantity - b)
                      );
                    })[0],
                };
              })
            );
            return dispatch(fetchIngredientsSuccess(data));
          case "CopyPartnerIngredientsQty":
            data.ingredient_details.forEach((i) =>
              i.ingredients.forEach((j) => {
                j.admin_suggested = {
                  _destroy: false,
                  _new: false,
                  is_required: true,
                  total_quantity: j.cook_suggested?.total_quantity
                    ,
                };
              })
            );
            return dispatch(fetchIngredientsSuccess(data)); 
          case "GetAdjustedQty":
            let cookQtyPosition;
            let systemQtyPosition;
            let cookDiff;
            let systemDiff;

            data.ingredient_details.forEach((i) =>
              i.ingredients.forEach((j) => {
                cookQtyPosition = j.suggested_quantities.split(",").indexOf(
                  j.suggested_quantities.split(",").sort((a, b) => {
                    return (
                      Math.abs(j.cook_suggested?.total_quantity - a) -
                      Math.abs(j.cook_suggested?.total_quantity - b)
                    );
                  })[0]
                );
                systemQtyPosition = j.suggested_quantities.split(",").indexOf(
                  j.suggested_quantities.split(",").sort((a, b) => {
                    return (
                      Math.abs(j.system_suggested?.total_quantity - a) -
                      Math.abs(j.system_suggested?.total_quantity - b)
                    );
                  })[0]
                );
                cookDiff =
                  parseInt(cookQtyPosition) > parseInt(systemQtyPosition)
                    ? parseInt(cookQtyPosition) - parseInt(systemQtyPosition)
                    : null;
                systemDiff =
                  parseInt(systemQtyPosition) > parseInt(cookQtyPosition)
                    ? parseInt(systemQtyPosition) - parseInt(cookQtyPosition)
                    : null;
                if (cookQtyPosition === systemQtyPosition) {
                  j.admin_suggested = {
                    _destroy: false,
                    _new: false,
                    is_required: true,
                    total_quantity:
                      j.suggested_quantities.split(",")[systemQtyPosition],
                  };
                } else if (cookDiff && cookDiff <= 2) {
                  j.admin_suggested = {
                    _destroy: false,
                    _new: false,
                    is_required: true,
                    total_quantity:
                      j.suggested_quantities.split(",")[systemQtyPosition + 1],
                  };
                } else if (cookDiff && cookDiff > 2) {
                  j.admin_suggested = {
                    _destroy: false,
                    _new: false,
                    is_required: true,
                    total_quantity: "",
                    redQtyAlert: true,
                  };
                } else if (systemDiff && systemDiff > 2) {
                  j.admin_suggested = {
                    _destroy: false,
                    _new: false,
                    is_required: true,
                    total_quantity: "",
                    redQtyAlert: true,
                  };
                } else if (systemDiff && systemDiff <= 2) {
                  j.admin_suggested = {
                    _destroy: false,
                    _new: false,
                    is_required: true,
                    total_quantity:
                      j.suggested_quantities.split(",")[systemQtyPosition - 1],
                  };
                }
              })
            );

            return dispatch(fetchIngredientsSuccess(data));

          default:
            data.ingredient_details.forEach((i) =>
              i.ingredients.forEach((j) => {
                // j.admin_suggested._destroy = false;

                j.admin_suggested = {
                  _destroy: false,

                  _new: false,

                  is_required: true,

                  nearestQty: false,
                  // ...j.cook_suggested,
                  ...j.admin_suggested,
                };
              })
            );

            return dispatch(fetchIngredientsSuccess(data));
        }
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchIngredientsFailure(errorMsg));
      });
  };
};

// -------------------------APPLIANCES-----------------

const fetchAppliancesRequest = () => {
  return {
    type: FETCH_APPLIANCES_REQUEST,
  };
};
export const fetchAppliancesSuccess = (data) => {
  return {
    type: FETCH_APPLIANCES_SUCCESS,
    payload: data,
  };
};
const fetchAppliancesFailure = (error) => {
  return {
    type: FETCH_APPLIANCES_FAILURE,
    payload: error,
  };
};
export const setAppliances = (data) => {
  return {
    type: SET_APPLIANCES,
    payload: data,
  };
};

// Fetch Appliances data
export const fetchAppliances = (bookingId) => {
  return (dispatch) => {
    dispatch(fetchAppliancesRequest());
    axios({
      method: "GET",
      url: `/admin/booking/${bookingId}/appliances`,
    })
      .then((res) => {
        let data = res.data.data;
        data.required_appliances = data.required_appliances.map((appliance) => {
          if (appliance.appliance_id === undefined) {
            appliance.type = [
              {
                appliance_id: `${appliance.appliance_name}_none`,
                appliance_image: "https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/red_block.png",
                available: false,
                is_special: false,
                name: `I don't have ${appliance.appliance_name}`,
              },
              ...appliance.type,
            ];
          }
          return appliance;
        });
        dispatch(fetchAppliancesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchAppliancesFailure(errorMsg));
      });
  };
};
