import {
  EDIT_HISTORY_REQUEST,
  EDIT_HISTORY_SUCCESS,
  EDIT_HISTORY_FAILURE,
} from "./types";
import axios from "axios";

const editHistoryRequest = () => ({ type: EDIT_HISTORY_REQUEST });
const editHistorySuccess = (data) => ({
  type: EDIT_HISTORY_SUCCESS,
  payload: data,
});
const editHistoryFailure = (err) => ({
  type: EDIT_HISTORY_FAILURE,
  payload: err,
});

// fetch cook edit history
export const fetchCookEditHistory = (id) => {
  return (dispatch) => {
    dispatch(editHistoryRequest());
    axios({
      method: "GET",
      url: `/admin/cook/logs-history/${id}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data.data.history_logs;
        dispatch(editHistorySuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(editHistoryFailure(errorMsg));
      });
  };
};
