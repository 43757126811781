import {
  FETCH_ALL_INGREDIENTS_REQUEST,
  FETCH_ALL_INGREDIENTS_SUCCESS,
  FETCH_ALL_INGREDIENTS_FAILURE,
  FETCH_ALL_APPLIANCES_REQUEST,
  FETCH_ALL_APPLIANCES_SUCCESS,
  FETCH_ALL_APPLIANCES_FAILURE,
} from "./types";

import axios from "axios";

// INGREDIENTS
const fetchIngredientsRequest = () => {
  return { type: FETCH_ALL_INGREDIENTS_REQUEST };
};
const fetchIngredientsSuccess = (data) => {
  return { type: FETCH_ALL_INGREDIENTS_SUCCESS, payload: data };
};
const fetchIngredientsFailure = (err) => {
  return { type: FETCH_ALL_INGREDIENTS_FAILURE, payload: err };
};

// Fetch All ingredients
export const fetchAllIngredients = (type, vegT, userFilter) => {
  return (dispatch) => {
    let url = "/ingredients";
    let filters = {
      is_veg: vegT ? vegT : null,
      type: type ? type : null,
      added_by: userFilter ? userFilter : null,
    };

    dispatch(fetchIngredientsRequest());
    axios({
      method: "GET",
      url,
      params: filters,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data.data.ingredients;

        dispatch(fetchIngredientsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchIngredientsFailure(errorMsg));
      });
  };
};

// APPLIANCES
const fetchAppliancesRequest = () => {
  return { type: FETCH_ALL_APPLIANCES_REQUEST };
};
const fetchAppliancesSuccess = (data) => {
  return { type: FETCH_ALL_APPLIANCES_SUCCESS, payload: data };
};
const fetchAppliancesFailure = (err) => {
  return { type: FETCH_ALL_APPLIANCES_FAILURE, payload: err };
};

// Fetch All appliances
export const fetchAllAppliances = (
  specialApplianceFilter,
  altApplianceFilter
) => {
  const filters = {
    is_special: specialApplianceFilter ? specialApplianceFilter : null,
    has_alternative_appliance: altApplianceFilter ? altApplianceFilter : null,
  };
  return (dispatch) => {
    dispatch(fetchAppliancesRequest());
    axios({
      method: "GET",
      url: `/appliances?with_dishes_counts=${true}`,
      params: filters,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data.data.appliances;

        dispatch(fetchAppliancesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchAppliancesFailure(errorMsg));
      });
  };
};
