import firebase from "firebase/app";
import "firebase/messaging";

import "firebase/analytics";
import "firebase/database";
import axios from "axios";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCCDGJ0VMO0R7yfidyzcswPDg-B9Xw8eqU",
  authDomain: "coox-admin.firebaseapp.com",
  databaseURL: "https://coox-admin.firebaseio.com",
  projectId: "coox-admin",
  storageBucket: "coox-admin.appspot.com",
  messagingSenderId: "351134436164",
  appId: "1:351134436164:web:8d24485ef71e2ff1bea511",
  measurementId: "G-W2EF4W844B",
};

export const initializeAnalytics = () => {
  // [START analytics_initialize]
  const analytics = firebase.analytics();
  // [END analytics_initialize]
};

firebase.initializeApp(firebaseConfig);

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
const database = firebase.database();
export const getToken = () => {
  if (messaging === undefined) return;
  return messaging
    .getToken({
      vapidKey: process.env.REACT_APP_FCM_KEY,
    })
    .then((currentToken) => {
      if (currentToken) {
        if (localStorage.getItem("fcmToken") !== currentToken) {
          axios({
            method: "post",
            url: "/user/fcm-token",
            data: {
              token: currentToken,
            },
          })
            .then((res) => {
              localStorage.setItem("fcmToken", currentToken);
              console.log("fcm token saved");
            })
            .catch((err) => {
              console.log("couldn't save fcm token");
            });
        }
        console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export const setDbValue = (obj) => {
  const ref = database.ref("coox_admin");
  const refData = database.ref("can_accept_data");
  // Write the input value to the Realtime Database
  if (obj["can_accept_common_meassage"]) refData.set(obj);
  else ref.set(obj);
};
export const getDbValue = (key) => {
  const ref = database.ref("coox_admin");
  // Write the input value to the Realtime Database
  ref.get(key);
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
