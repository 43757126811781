import React from "react";
import { Route, Redirect } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";

const PrivateRoute = React.memo(
  ({ component: Component, roles, authUser, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        // check if route is restricted by role
        if (roles && roles.indexOf(authUser.roles[0]) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  )
);

const MSP = (state) => ({
  authUser: state.login.authUser,
});

export default connect(MSP)(PrivateRoute);
