import React, { useState, useEffect } from "react";
import axios from "axios";
// import { compose } from "redux";
import { connect } from "react-redux";
import { authUserSet, authTokenSet, setGlobalLoader } from "../../redux";
import CustomToast from "../../reusables/CustomToast";
const checkRequests = (Wrapped) => {
  // const accessToken = JSON.parse(localStorage.getItem("authToken"))
  //   ?.accessToken;

  function CheckRequests(props) {
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState({ body: "", delay: "", success: "" });
    const { setGlobalLoader, authToken, authUserSet, authTokenSet } = props;
    axios.defaults.headers.common.Authorization = `Bearer ${authToken?.accessToken}`;
    useEffect(() => {
      // Add a request interceptor
      let numberOfAjaxCAllPending = 0;
      axios.interceptors.request.use(
        (config) => {
          numberOfAjaxCAllPending++;
          // Do something before request is sent
          setGlobalLoader(true);
          return config;
        },
        (error) => {
          // Do something with request error
          setGlobalLoader(false);
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          // Do something with response data
          // alert('Something!');`
          numberOfAjaxCAllPending--;
          if (numberOfAjaxCAllPending === 0) {
            // hide loader
            setGlobalLoader(false);
          }

          return response;
        },
        (error) => {
          numberOfAjaxCAllPending--;
          switch (error.response?.status) {
            case 401:
              // logout & clear localstorage, redirect to sign in
              setGlobalLoader(false);
              setShowToast(true);
              setToast({
                body: "jwt expired!",
                delay: 5000,
                success: true,
              });
              localStorage.removeItem("authUser");
              localStorage.removeItem("authToken");

              authUserSet(null);
              authTokenSet(null);
              if (error.config.url !== "/auth/login-and-signup") {
                window.location.href = "/";
              }

              break;
              // return (
              //   <CustomToast
              //     show={showToast}
              //     setShow={() => setShowToast(false)}
              //     toastBody={toast.body}
              //     delay={toast.delay}
              //     success={toast.success}
              //   />
              // );

            case 429:
              setGlobalLoader(false);
              alert("Too many requests. Please try again later.");
              break;
            case 403:
              setGlobalLoader(false);
              alert(error.response.data.message);
              break;
            case 400:
              setGlobalLoader(false);
              // const errorString = error.response.data.errors
              // 	.map(function(error) {
              // 		return error.messages.join('. ');
              // 	})
              // 	.join('. ');
              // alert(errorString);
              // alert('Invalid Data');
              break;
            case 406:
              setGlobalLoader(false);
              // const errorString = error.response.data.errors
              // 	.map(function (error) {
              // 		return error.messages.join('. ');
              // 	})
              // 	.join('. ');
              // alert(errorString);
              // alert('Invalid Data');
              break;
            default:
              setGlobalLoader(false);
              break;
          }
          // Do something with response error
          return Promise.reject(error);
        }
      );
    }, []);

    return <Wrapped {...props} />;
  }
  const MSP = (state) => {
    return {
      authToken: state.login.authToken,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
      authTokenSet: (authUserData) => dispatch(authTokenSet(authUserData)),
      setGlobalLoader: (value) => dispatch(setGlobalLoader(value)),
    };
  };
  return connect(MSP, mapDispatchToProps)(CheckRequests);
};

export default checkRequests;
