import {
	ADD_ADMIN_REQUEST,
	ADD_ADMIN_REQUEST_SUCCESS,
	ADD_ADMIN_REQUEST_FAILURE,
} from './addAdminTypes';

const initialState = {
	loading: false,
	data: [],
	error: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_ADMIN_REQUEST:
			return {
				...state,
				loading: true,
			};
		case ADD_ADMIN_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: '',
			};
		case ADD_ADMIN_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
