import {
  FETCH_DISH_TYPES_REQUEST,
  FETCH_DISH_TYPES_SUCCESS,
  FETCH_DISH_TYPES_FAILURE,
} from "./types";

const INITIAL_STATE = {
  dish_types_mappings: {
    loading: false,
    error: "",
    data: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DISH_TYPES_REQUEST:
      return {
        ...state,
        dish_types_mappings: {
          loading: true,
          error: "",
          data: [],
        },
      };
    case FETCH_DISH_TYPES_SUCCESS:
      return {
        ...state,
        dish_types_mappings: {
          loading: false,
          error: "",
          data: action.payload,
        },
      };
    case FETCH_DISH_TYPES_FAILURE:
      return {
        ...state,
        dish_types_mappings: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};

export default reducer;
