import {
  FETCH_CITIES_AND_AREAS_REQUEST,
  FETCH_CITIES_AND_AREAS_SUCCESS,
  FETCH_CITIES_AND_AREAS_FAILURE,
} from "./types";

const INITIAL_STATE = {
  citiesAndAreas: [],
  loading: false,
  error: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CITIES_AND_AREAS_REQUEST:
      return { ...state, loading: true };
    case FETCH_CITIES_AND_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        citiesAndAreas: action.payload,
        error: "",
      };
    case FETCH_CITIES_AND_AREAS_FAILURE:
      return {
        ...state,
        loading: false,
        citiesAndAreas: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
