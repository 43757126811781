import axios from "axios";
import { BASE_URL } from "../../config";
import {
  FETCH_ALL_USERS_REQUEST,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILURE,
} from "./getuserTypes";

const fetchAllUsersRequest = () => {
  return {
    type: FETCH_ALL_USERS_REQUEST,
  };
};
const fetchAllUsersSuccess = (data) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: data,
  };
};
const fetchAllUsersFailure = (error) => {
  return {
    type: FETCH_ALL_USERS_FAILURE,
    payload: error,
  };
};

// Send fetch all users request
export const fetchUsers = (activePage) => {
  return (dispatch) => {
    dispatch(fetchAllUsersRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/user/all-users?_limit=100&_page=${activePage}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.accessToken,
      },
    })
      .then((res) => {
        const data = res.data;
        dispatch(fetchAllUsersSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data.message;
        dispatch(fetchAllUsersFailure(errorMsg));
      });
  };
};
