import { SET_CROPPED_IMAGE_DATA, CLEAR_CROPPED_IMAGE_DATA } from "./types";

export const setCroppedImageData = (data) => ({
  type: SET_CROPPED_IMAGE_DATA,
  payload: data,
});

export const clearCroppedImageData = () => ({
  type: CLEAR_CROPPED_IMAGE_DATA,
});
