import React from "react";
import { connect } from "react-redux";

import { authUserSet, authTokenSet } from "../../redux";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.authUserSet(JSON.parse(localStorage.getItem("authUser")));
      this.props.authTokenSet(JSON.parse(localStorage.getItem("authToken")));
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
