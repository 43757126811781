export const FETCH_ALL_COOKS_REQUEST = "FETCH_ALL_COOKS_REQUEST";
export const FETCH_ALL_COOKS_SUCCESS = "FETCH_ALL_COOKS_SUCCESS";
export const FETCH_ALL_COOKS_FAILURE = "FETCH_ALL_COOKS_FAILURE";
export const SET_ALL_COOKS = "SET_ALL_COOKS";

export const FLAG_COOK_REQUEST = "FLAG_COOK_REQUEST";
export const FLAG_COOK_SUCCESS = "FLAG_COOK_SUCCESS";
export const FLAG_COOK_FAILURE = "FLAG_COOK_FAILURE";

export const SAVE_HOME_PAGE_CURRENT_FILTERS = "SAVE_HOME_PAGE_CURRENT_FILTERS";
export const SAVE_ASSIGNED_COOKS_CURRENT_FILTERS =
  "SAVE_ASSIGNED_COOKS_CURRENT_FILTERS";
