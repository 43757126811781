// Describe order types
export const FETCH_DESCRIBE_YOUR_ORDER_REQUEST =
  "FETCH_DESCRIBE_YOUR_ORDER_REQUEST";
export const FETCH_DESCRIBE_YOUR_ORDER_SUCCESS =
  "FETCH_DESCRIBE_YOUR_ORDER_SUCCESS";
export const FETCH_DESCRIBE_YOUR_ORDER_FAILURE =
  "FETCH_DESCRIBE_YOUR_ORDER_FAILURE";
export const SET_DESCRIBE_YOUR_ORDER = "SET_DESCRIBE_YOUR_ORDER";
export const UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES =
  "UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES";

// Ingredient types
export const FETCH_INGREDIENTS_REQUEST = "FETCH_INGREDIENTS_REQUEST";
export const FETCH_INGREDIENTS_SUCCESS = "FETCH_INGREDIENTS_SUCCESS";
export const FETCH_INGREDIENTS_FAILURE = "FETCH_INGREDIENTS_FAILURE";
export const SET_INGREDIENTS = "SET_INGREDIENTS";

// Appliances types
export const FETCH_APPLIANCES_REQUEST = "FETCH_APPLIANCES_REQUEST";
export const FETCH_APPLIANCES_SUCCESS = "FETCH_APPLIANCES_SUCCESS";
export const FETCH_APPLIANCES_FAILURE = "FETCH_APPLIANCES_FAILURE";
export const SET_APPLIANCES = "SET_APPLIANCES";
