import {
  FETCH_DESCRIBE_YOUR_ORDER_REQUEST,
  FETCH_DESCRIBE_YOUR_ORDER_SUCCESS,
  FETCH_DESCRIBE_YOUR_ORDER_FAILURE,
  SET_DESCRIBE_YOUR_ORDER,
  UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES,
  FETCH_INGREDIENTS_REQUEST,
  FETCH_INGREDIENTS_SUCCESS,
  FETCH_INGREDIENTS_FAILURE,
  FETCH_APPLIANCES_REQUEST,
  FETCH_APPLIANCES_SUCCESS,
  FETCH_APPLIANCES_FAILURE,
  SET_APPLIANCES,
  SET_INGREDIENTS,
} from "./types";

const initialState = {
  describe: {
    loading: false,
    data: [],
    error: "",
  },
  ingredients: {
    loading: false,
    data: [],
    error: "",
  },
  appliances: {
    loading: false,
    data: [],
    error: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Describe order
    case FETCH_DESCRIBE_YOUR_ORDER_REQUEST:
      return {
        ...state,
        describe: {
          ...state.describe,
          loading: true,
        },
      };
    case FETCH_DESCRIBE_YOUR_ORDER_SUCCESS:
      return {
        ...state,
        describe: {
          ...state.describe,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case FETCH_DESCRIBE_YOUR_ORDER_FAILURE:
      return {
        ...state,
        describe: {
          ...state.describe,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case SET_DESCRIBE_YOUR_ORDER:
      return {
        ...state,
        describe: {
          ...action.payload,
        },
      };
    case UPDATE_DESCRIBE_YOUR_ORDER_BOOKING_DISHES:
      return {
        ...state,
        describe: {
          ...state.describe,
          data: {
            ...state.describe.data,
            booking_dishes: [...action.payload],
          },
        },
      };
    // Ingredients
    case FETCH_INGREDIENTS_REQUEST:
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          loading: true,
        },
      };
    case FETCH_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case FETCH_INGREDIENTS_FAILURE:
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case SET_INGREDIENTS:
      return {
        ...state,
        ingredients: {
          ...state.ingredients,
          loading: false,
          data: {
            ...state.ingredients.data,
            ingredient_details: action.payload,
          },
          error: "",
        },
      };

    // Appliances
    case FETCH_APPLIANCES_REQUEST:
      return {
        ...state,
        appliances: {
          ...state.appliances,
          loading: true,
        },
      };
    case FETCH_APPLIANCES_SUCCESS:
      return {
        ...state,
        appliances: {
          ...state.appliances,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case FETCH_APPLIANCES_FAILURE:
      return {
        ...state,
        appliances: {
          ...state.appliances,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case SET_APPLIANCES:
      return {
        ...state,
        appliances: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
