import { AUTH_USER_SET, AUTH_TOKEN_SET , AUTH_EMPLOYEE_SET } from "./loginTypes";

export const authUserSet = (authUserData) => {
  return { type: AUTH_USER_SET, payload: authUserData };
};
export const authTokenSet = (authTokenData) => {
  return { type: AUTH_TOKEN_SET, payload: authTokenData };
};
export const authEmployeeSet = (authEmployeeData) => {
  return { type: AUTH_EMPLOYEE_SET, payload: authEmployeeData };
};