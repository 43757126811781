import {
  EDIT_HISTORY_REQUEST,
  EDIT_HISTORY_SUCCESS,
  EDIT_HISTORY_FAILURE,
} from "./types";

const initialState = {
  loading: false,
  cookEditHistory: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        cookEditHistory: action.payload,
        error: "",
      };
    case EDIT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        cookEditHistory: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
